.auth-input-container {
  position: relative;
  width: 100%;
  display: flex;
}

.auth-input {
  width: 100%;
  display: inline-block;
  border-bottom: 2px solid lightgrey;
  font-size: 16px;
  padding: 2px 30px;
  margin-bottom: 28px;
}

.auth-input:focus {
  outline: none;
  border-bottom: 2px solid;
  border-color: rgba(59, 130, 246)
}

.auth-icon-wrapper {
  margin-bottom: 28px;
  border-bottom: 2px solid lightgrey;
}

.auth-icon-wrapper-focused {
  border-color: rgba(59, 130, 246)
}

.auth-icon {
  color: #c1c1c1;
  position: absolute;
  margin-top: 3px;
}

.auth-icon-password-eye {
  position: absolute;
  right: 0;
  top: 4px;
  font-size: 20px;
  color: #c1c1c1;
}

.auth-validation-msg {
  border-radius: 5px;
  padding: 2px;
  position: absolute;
  top: -20px;
  /* left: 10px; */
  display: flex;
  word-break: break-all;
  font-size: 12px;
  color: red;
  transition: display 2s ease-in-out;
}

.hide {
  display: none;
}

.green-check {
  color: #00FF00;
  margin-top: 6px;
}

.red-xmark {
  color: #ff0000;
  margin-top: 6px;
}